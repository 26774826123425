import React from "react";
import Button from "../common/button";

function Web3BarFight() {
  return (
    <div className="bg-black w-full pt-20">
      <div className="flex flex-col items-center justify-center mx-auto container text-white relative z-10">
        <div className="max-w-screen-xl bg-contain bg-no-repeat">
          <div className="h-auto w-full relative px-4">
            <div className="flex flex-col items-center mx-auto container text-white">
              <h3 className="font-lavaarabic text-5xl md:text-6xl leading-tight mb-6 text-center">
                DON'T BE A COWARD <br /> JOIN THE WEB3 FIGHT CLUB
              </h3>
              <div className="w-full justify-between flex flex-col md:flex-row items-bottom items-center gap-x-8 gap-y-8 pb-5 md:pb-0 relative z-10">
                <Partner
                  href="https://boredapeyachtclub.com/"
                  imgUrl="images/bayc-footer.webp"
                  alt="bayc-footer"
                  dataaosduration="400"
                />
                <Partner
                  href="https://twitter.com/ChungosNFT"
                  imgUrl="images/chungo+logo.png"
                  alt="chungo"
                  dataaosduration="400"
                />
                <Partner
                  href="https://cryptodadsnft.com/"
                  imgUrl="images/crypto-dads.png"
                  alt="crypto-dads"
                  dataaosduration="400"
                />
                <Partner
                  href="https://www.martiansvsroughnecks.com/"
                  imgUrl="images/mvroughnecks_logo2_wide.png"
                  alt="mvroughnecks"
                  dataaosduration="600"
                />
              </div>
              <div className="w-full justify-evenly flex flex-col md:flex-row items-bottom items-center gap-x-8 gap-y-8 relative z-10">
                <Partner
                  href="https://mooncatz.io/"
                  imgUrl="images/mooncatz.png"
                  alt="mooncatz"
                  dataaosduration="600"
                />
                <Partner
                  href="#"
                  imgUrl="images/the-plague-logo.png"
                  alt="the_plague_logo"
                  className="-mt-1"
                  dataaosduration="800"
                />
                <Partner
                  href="https://www.supremekong.com/"
                  imgUrl="images/supreme_kong_logo_-_vertical_copy.png"
                  alt="supreme_kong_logo"
                  className="-mt-1"
                  dataaosduration="800"
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-12 max-w-screen-lg pt-10">
                <div className="flex flex-col" data-aos="fade-up">
                  <p>
                    The <span className="text-lightGreen">web3 fight club</span>{" "}
                    is growing. More projects are being integrated into the
                    game, providing their communities with{" "}
                    <span className="text-lightGreen">real utility</span>. Bar
                    Fights allows your NFTs and digital identities to come to
                    life in a{" "}
                    <span className="text-lightGreen">
                      ground breaking P2E game
                    </span>
                    , which your community will absolutely love.
                  </p>
                </div>
                <div className="flex flex-col" data-aos="fade-up">
                  <p>
                    Want to integrate your project into the fight club? Open a
                    support ticket in our discord or get in touch with our team.
                  </p>
                  <a href="http://discord.gg/VyxVr4byxH" target="__blank">
                    <Button title="Apply" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col items-center justify-center mx-auto container p-1 pb-8 pt-5 text-white"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="grid grid-cols-1 pl-0 lg:pl-8 max-w-screen-xl">
                <div className="flex flex-col items-center justify-center md:px-4">
                  <img className="" src="/images/fight.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web3BarFight;

function Partner({
  href = "",
  imgUrl = "",
  alt = "",
  className = "",
  dataaosduration = null,
}) {
  return (
    <a
      href={href}
      target="__blank"
      rel="noreferrer"
      className={`py-3 md:py-4 ${className}`}
      data-aos-duration={dataaosduration}
      data-aos="fade-up"
    >
      <img className="w-52" src={imgUrl} alt={alt} />
    </a>
  );
}
