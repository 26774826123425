import React from "react";
import { isMobileOnly, isIOS } from 'react-device-detect';

//Provides standard animated hero functionality for top of page hero components
function AnimatedHero({ bgimg = "", video = "", position = "items-center", children , className = "" }) {
    var renderVideo = true;
    if (isMobileOnly && isIOS) renderVideo = true;
    return (

        <>

        <div style={{ backgroundImage: "url(" + bgimg + ")" }}  className={className} >
            <div className={"w-full flex flex-col " + position + " justify-center mx-auto text-white pl-4 pr-4 z-20"}>
                {children}
            </div>
            {!renderVideo ? <></> :
                <video id="background-video" className="w-full videohero" autoPlay loop muted playsInline>
                    <source src={video} type="video/mp4" />
                </video>
            }
        </div>
           
        </>
    );
}

export default AnimatedHero;
