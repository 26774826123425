import React from "react";
import AnimatedHero from "../common/animatedHero";

function AnimatedDcredHero() {
    return (

        <>
            <AnimatedHero bgimg="images/dcred-bg.png" video="images/dcred-bg-hd.mp4" position="items-center md:items-end pt-5 sm:pt-0 md:pr-24 xl:pr-48" className="hero-banner flex">
                <div className="w-full sm:p-8 sm:w-10/12 lg:w-4/12 bg-black/[0.6] p-5 z-20">
                    <div className="flex flex-col items-start justify-center mx-auto text-xs lg:text-sm font-spacegrotesk z-20">
                        <h3 className="font-lavaarabic text-5xl md:text-6xl text-white pb-8">What is $Dcred?</h3>
                        <p>$DCRED (Degen Street Cred) is our in-game utility token that is fundamental to progressing within the game. Use $DCRED to purchase weapons, armour, finishing moves, opening loot boxes and more.</p>
                        <p>$DCRED is earned by winning Bar Fights. You'll need to be in the gang to claim your rewards though.</p>
                        <p>All Genesis Degen Gang holders can yield 100 $DCRED a day from looting sprees (staking).</p>
                        <p>Token Launch Coming Soon.</p>
                    </div>
                </div>
            </AnimatedHero>
        </>
    );
}

export default AnimatedDcredHero;
