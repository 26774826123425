import React from 'react'

const TheMemeFactory2 = () => {
  return (
    <div className="relative px-4 pt-14 bg-no-repeat bg-left-bottom z-10">
      <div className="big-bg-text absolute z-0 inset-x-0 top-0 leading-tight text-center font-lavaarabic">MEME MEME</div>
      <div className="h-auto w-full relative">
        <div className="flex flex-col items-center justify-center" data-aos="fade-up">
          <h3 className="font-lavaarabic text-5xl md:text-6xl text-white pb-16">THE MEME FACTORY</h3> 
        </div>
        <div className="flex flex-col items-center justify-center text-center mx-auto container max-w-screen-sm text-white" data-aos="fade-up">
            <p>As the degens pursued their fight to the top, searching for the <span className="text-lightGreen">toughest mfrs</span> out there, the SEC continued to crack down. It became increasingly difficult for the gang to fight the propaganda the SEC was churning out.</p>
            <p>But the degens knew, <span className="text-lightGreen">whoever controls the memes, controls the universe</span>. And these degens knew memes. It was in their DNA.</p>
            <p>A few memes here and there though were never going to cut it, so the gang came up with a plan for <span className="text-lightGreen">mass production of memes</span>.</p>
        </div>
        <div className="flex flex-col items-center justify-center mx-auto container pt-16 text-white">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-12 max-w-screen-md pt-6 bg-black memes-overlay">
            <div className="flex flex-col" data-aos="fade-up">
              <p>They reached out to their ally Mr Shmusk on Mars for funding, knowing his love of memes and in secret built <span className="text-lightGreen">The Meme Factory</span> on Earth, far away from prying eyes.</p>
            </div>
            <div className="flex flex-col" data-aos="fade-up">
              <p>Production lines were set up swiftly and meme output surged. The factory quickly became a beacon of creative talent, renowned for it's memes and shitposts.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mx-auto container text-white lg:-mt-6" data-aos="fade-up" data-aos-duration="1000">
          <div className="grid grid-cols-1 pl-0 lg:pl-8 max-w-screen-xl">
            <div className="flex flex-col items-center justify-center md:px-4">
              <img className=""src="/images/elon-deal.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TheMemeFactory2
