import React,{useState} from "react";
import { audioList } from "./profileaudiobutton";

const ProfileButton = () =>{
    
    const getCurrentAudio= ()=> {
        return audioList.find(audio => false === audio.audio.paused);
    }
    const handleAudio = (nextAudio) => {
        const currentAudio = getCurrentAudio();
        
        if (currentAudio && currentAudio.audio !== nextAudio) {
            currentAudio.audio.pause();
        }
        nextAudio.currentTime = 0;
        nextAudio.paused ? nextAudio.play() : nextAudio.pause();
    }
    return(
        <>
        <div className="w-full flex flex-col items-center px-4 pt-4 pb-14 md:pt-20 bg-black relative">
            <div className="flex flex-row justify-center items-center w-full relative">
                <div className="flex flex-col items-center justify-center text-center mx-auto container max-w-screen-md text-white" data-aos="fade-up">
                    <h3 className="font-lavaarabic text-5xl md:text-6xl leading-tight mb-6 text-center">Literally Street Fighter Style</h3>
                    <p>We're proud to have worked with the legend voice actor, <a href="https://en.wikipedia.org/wiki/Kyle_Hebert" target="_blank"><span className="text-lightGreen">Kyle Hebert</span></a>, famously known for voicing Ryu in <span className="text-lightGreen">Street Fighter</span>.</p>
                </div>
            </div>
        </div>

        <div className="mx-auto container pb-20">
            <div className="text-center">
                {audioList.map((audio, index) =>
                    <div key={index} onClick={() => handleAudio(audio.audio)} className="profilebtn">
                        <img src={audio.img} className="btn-normal"/>
                        <img src={audio.hoverimg} className="btn-hover"/>
                    </div>
                )}
            </div> 
        </div>

        </>
    )
}
export default ProfileButton;