import React from "react";
import Button from "../common/button";

function DecredCoin() {
  return (
    <div style={{ backgroundImage: `url(images/decredCoin_bg.png)` }} className="bg-black bg-cover bg-center h-full w-full pt-10 md:pt-28 pb-10 md:pb-24 px-4"
    >
      <div className="flex flex-col items-center justify-center mx-auto container text-white lg:pr-8">
        <div className="grid grid-cols-1 md:grid-cols-8 gap-8 max-w-screen-lg dcred-block relative">
          <div className="md:col-span-4 bg-black/70 relative lg:pl-6 pb-6">
            <h3 className="font-lavaarabic text-5xl md:text-6xl leading-tight mb-6" data-aos="fade-up">ONE COIN TO RULE THEM ALL, $DCRED</h3>
            <p data-aos="fade-up" data-aos-duration="300">Degen Street Cred, <span className="text-lightGreen">$DCRED</span>. This whole place runs on it and these degens will do whatever they need to do to get their hands on it.</p>
            <p data-aos="fade-up" data-aos-duration="600">Looting sprees, robbing rival bars or taking names in <span className="text-lightGreen">bar fights</span>, there are plenty of ways to earn your $DCRED and plenty of ways to spend it down the blackmarket.</p>
            <Button title="$DCRED" navigateTo="/dcred" className="mt-3" data-aos="fade-up" data-aos-duration="900"/>
          </div>
          <div className="md:col-span-4 flex flex-col items-center justify-center" data-aos="fade-up" data-aos-duration="600">
            <img className="" src="/images/decredCoin_pic.png" alt=""/>
          </div>
        </div>      
      </div>
    </div>
  );
}

export default DecredCoin;
