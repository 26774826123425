import React from 'react'

const Tournaments = () => {
  return (
    <div className="w-full flex flex-col items-center px-4 pt-4 pb-20 md:py-20 bg-black relative">
      <div className="big-bg-text absolute z-0 inset-x-0 -top-10 leading-tight text-center font-lavaarabic">DEGEN GANG</div>
      <div className="flex flex-row justify-center items-center w-full relative">
          <div className="flex flex-col items-center justify-center text-center mx-auto container max-w-screen-md text-white" data-aos="fade-up">
            <h3 className="font-lavaarabic text-5xl md:text-6xl leading-tight mb-6 text-center">TOURNAMENTS</h3>
            <p>In the run up to the full game launch, there will be regular monthly tournaments between different projects in the web3 fight club, with prizes and rewards to be won! These rewards include 1 for 1 NFTs and more.</p>
          </div>
      </div>
  </div>
  )
}

export default Tournaments 
