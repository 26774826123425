import Vimeo from '@u-wave/react-vimeo'
import React from 'react'

const BarFightVideo = () => {
  return (
    <div className='w-full bg-cover bg-no-repeat bar-fight-bg relative' style={{ backgroundImage: `url(images/fight_video_poster.jpg)`}}>
      <Vimeo
        video="https://vimeo.com/789244502"
        autoplay
        loop
        background
        responsive
        playsInline
        controles
      />
    </div>
  )
}

export default BarFightVideo
