import React from "react";
import Button from "../common/button";

function FortuneFavours() {
  return (
    <div className="bg-black w-full relative pt-24 pb-10">
      <div className="big-bg-text absolute z-0 inset-x-0 -top-40 leading-tight text-center font-lavaarabic">GMGMGMGM</div>
      <div className="flex flex-col items-center justify-center mx-auto container text-white relative z-10">
        <div className="max-w-screen-xl bg-contain bg-no-repeat fight-block mob-bg-none" style={{ backgroundImage: `url(images/reply-guys.png)` }} >
        <div className="w-full text-center pb-10 md:hidden">
          <img className=""src="/images/reply-guys.png" alt="" />
        </div>
          <div className="w-full md:w-6/12 lg:w-2/5 float-right text-white">
            <div className="bg-black">
              <h3 className="font-lavaarabic text-5xl md:text-6xl pb-5" data-aos="fade-up">FORTUNE FAVOURS THE REPLY GUYS</h3>
              <p data-aos="fade-up" data-aos-duration="1000">The gang promotes from within. Those that create <span className="text-lightGreen">the best and most worthy memes</span> can climb up the ladder to the top. </p>
              <p data-aos="fade-up" data-aos-duration="1000">Think your meme game is strong? Head to the meme factory and apply.  We're hiring.</p>
              <div className="pt-5" data-aos="fade-up" data-aos-duration="1000">
                <a href="http://discord.gg/VyxVr4byxH" target="__blank">
                  <Button title="APPLY" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FortuneFavours;
