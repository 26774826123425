import React, { useState } from "react";
import AnimatedBarFightHeroV2 from "../components/BarFightPage/animatedBarFightHeroV2";
import Join from "../components/common/joinmodule";
import Footer from "../components/common/navbar/footer";
import Navbar from "../components/common/navbar/navbar";
import BarFightimage from "../components/BarFightPage/barFightimage";
import Web3FightClubBarFight from "../components/BarFightPage/web3FightClubBarFight";
import DecredCoinBarFight from "../components/BarFightPage/decredCoinBarFight";
import BarFightVideo from "../components/BarFightPage/fight";
import Web3BarFight from "../components/BarFightPage/web3farfight";
import ParallaxDegenLogo from "../components/homePage/parallaxDegenLogo";
import ProfileButton from "../components/BarFightPage/profilebutton";
import Tournaments from "../components/BarFightPage/tournaments";

export default function BarFight() {
  const [menu, setmenu] = useState(false);

  return (
    <>
      <Navbar menu={menu} setmenu={setmenu} />
      {/* <AnimatedBarFightHeroV2 /> */}
      <BarFightimage/>
      <Web3FightClubBarFight/>
      <BarFightVideo/>
      <Web3BarFight/>
      <Tournaments/>
      <ParallaxDegenLogo/>
      <ProfileButton/>
      <DecredCoinBarFight/>
      <Join />
      <Footer />
    </>
  );
}
