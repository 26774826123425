import React from "react";
import Button from "../common/button";

function WhyBeastsV2() {
    return (
        <div id="roadmap" style={{ backgroundImage: `url(images/decredCoin_bg.png)` }} className="bg-cover bg-center h-full w-full py-16 md:py-24 xl:py-32">
            <div className="flex flex-col items-center justify-center mx-auto container max-w-screen-lg px-5">
                <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-x-8 md:gap-x-16 gap-y-8 md:gap-y-16">
                    <div className="w-full" data-aos="fade-up" data-aos-duration="300">
                        <img src="images/Beasts-DCRED.jpg" alt="Beasts DCRED"></img>
                    </div>
                    <div className="text-center md:text-left lg:w-8/12">
                        <div data-aos="fade-up" data-aos-duration="200">
                            <h3 className="font-lavaarabic text-5xl md:text-6xl text-white pb-5 lg:pb-14">$DCRED</h3>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="400">
                            <p className="text-white mt-4">When 4 Beasts from the same faction (Evil or Based) are staked they will yield 50 $DCRED a day.</p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="600" className="mt-7 lg:mt-14">
                            <Button title="$DCRED" navigateTo="/dcred" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyBeastsV2;
