import React, { useState, useEffect } from "react";
import Degen from "./pages/homePage";
import Barfight from "./pages/barFightPage";
import DcredPage from "./pages/dcredPage";
import BeastsPageV2 from "./pages/beastsPagev2";
import ScrollToTop from "./components/common/scrollToTop";
import AuthProvider from "../src/components/providers/AuthProvider";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingPage from "./components/LoadingPage/loadingPage";
import AudioButton from "./components/AudioButton/AudioButton";
import song from "./assets/Degen-Gang-Website.mp3";
import AOS from "aos";
import "aos/dist/aos.css";
import TheMemeFactory from "./pages/thememefactory";

const DegenGang = () => {
  const [audioPlay] = useState(new Audio(song));
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const handleAudio = (play) => {
    setIsAudioPlaying(play)
  }
  useEffect(() => {
    isAudioPlaying ? audioPlay.play() : audioPlay.pause();
  }
);
useEffect(() => {
  AOS.init();
  AOS.refresh();
}, []);

  return (
    <div>
      <Router>
        <LoadingPage handleAudioClick={handleAudio}/>
        <AuthProvider>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Degen />} exact />
              <Route path="/dcred" element={<DcredPage />} exact />
              <Route path="/barfights" element={<Barfight />} exact />
              <Route path="/beasts" element={<BeastsPageV2 />} exact />
              <Route path="/meme-factory" element={<TheMemeFactory />} exact />
            </Routes>
          </ScrollToTop>
        </AuthProvider>
        <AudioButton handleClick={handleAudio} isAudioPlaying={isAudioPlaying} />
      </Router>
    </div>
  );
};

export default DegenGang;
