import React from "react";

function Memes() {
  return (
    <div className="bg-black relative px-4">
      <div className="big-bg-text absolute z-0 inset-x-0 -top-10 md:-top-36 leading-tight text-center font-lavaarabic">gmgmgmgm</div>
      <div className="h-auto w-full relative">
        <div className="flex flex-col items-center justify-center mx-auto container pt-20 text-white">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-12 max-w-screen-lg pt-10">
            <div className="flex flex-col items-center justify-center" data-aos="fade-up">
              <h3 className="font-lavaarabic text-5xl md:text-6xl">Who controls the memes, controls the Universe</h3> 
            </div>
            <div className="flex flex-col items-center justify-center" data-aos="fade-up">
              <p>That's the thing with gangs, once you're in, you join a community so strong you'll feel like a <span className="text-lightGreen">fkn king</span>. Nothing but <span className="text-lightGreen">degen vibes</span>, good times and <span className="text-lightGreen">memes</span> as far as they eye can see. </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mx-auto container p-1 pb-8 text-white" data-aos="fade-up" data-aos-duration="1000">
          <div className="grid grid-cols-1 pl-0 lg:pl-8 max-w-screen-xl">
            <div className="flex flex-col items-center justify-center md:px-4">
              <img className=""src="/images/pablo_new.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Memes;
