import { useState } from "react";
import Button from "../common/button";
import AuthModal from "./AuthModal";
import { useAuth } from "../providers/AuthProvider";

function ConnectWalletButton({
                               connectText = "Connect Wallet",
                               isthreed = false,
                               onClickhandler= null,
                               externalUrl=null
                             }) {

  const [toggleConnect, setToggleConnect] = useState(false);

  const { account } = useAuth();

  //Following code will fetch the balance from the wallet and format it vto 3 dp
  //const etherBalance = useEtherBalance(account)
  //const formattedEtherBalance = etherBalance ? parseFloat(formatEther(etherBalance)).toFixed(3) : null;

  return (
      <>
        {toggleConnect && <AuthModal closeModal={setToggleConnect}></AuthModal>}
        {!account && (
            <Button
                isthreed={isthreed}
                title={connectText}
                externalUrl={externalUrl}
                onClickhandler={onClickhandler}
                small
            />
        )}
      </>
  );
}

export default ConnectWalletButton;
