import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/degengang-logo-animation.json";
import Vimeo from "@u-wave/react-vimeo";

export default function LoadingPage({ handleAudioClick }) {
  
  const [loading, setLoading] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEnterButton = () => {
    handleAudioClick(true);
    setHideLoader((hideLoader) => !hideLoader);
    document.body.classList.remove("bodyloding");
  };
  let toggleClassCheck = hideLoader ? " loader_hidden" : "";
  let toggleLoadingBtn = loading ? " loader_visible" : "";

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 5000);
  });
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1);
  });
  return (
    <>
      <div className={`loader-container ${toggleClassCheck}`}>
        <Vimeo
          className="absolute z-0 w-full h-full inset-0 vimeo-style"
          video="https://vimeo.com/769892438"
          autoplay
          loop
          background
          responsive
          playsinline
          controles
          style={{ minHeight: "100vh", minWidth: "177.77vh" }}
        />
        {isLoading && (
          <div className="w-full h-350px flex justify-center absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-center z-10">
            <Lottie options={defaultOptions} height={275} width={225} />
            {!loading ? (
              <div className="absolute bottom-5 lg:bottom-0 left-0 right-0 font-spacegrotesk focus:outline-none px-8 py-3 text-xs md:text-base md:py-11px mt-6 lg:mt-8 text-white transition-all duration-300 ease-in-out transform">
                Loading...
              </div>
            ) : null}
            <div
              className={`absolute bottom-5 lg:bottom-0 left-0 right-0 loader-button-container${toggleLoadingBtn} mt-6 lg:mt-8`}
            >
              <button
                onClick={handleEnterButton}
                className="font-lavaarabic focus:outline-none px-8 py-3 text-2xl md:py-11px bg-redBrand text-white shadow-btn hover:shadow-none transition-all duration-300 ease-in-out hover:scale-110 hover:bg-limeGreen hover:text-redBrand transform"
              >
                ENTER FIGHT CLUB
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
