import React, { useState, useEffect } from "react";
import Modal from "../modal/Modal";
import OutsideClickHandler from "react-outside-click-handler";
import MetaMaskLogo from "../../assets/metamask.png";
import WalletConnectLogo from "../../assets/walletconnect.png";
import { isMobileOnly, isIOS } from 'react-device-detect';


import { useAuth } from "../providers/AuthProvider";

import "./AuthModal.scss";
const AuthModal = (props) => {
  const { account, message, connectWallet, connectMetaMask } = useAuth();
  const { closeModal } = props;

  useEffect(() => {
    if (account) closeModal(false);
  }, [account]);

  return (
    <Modal>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeModal(false);
        }}
      >
        <div className="auth-container font-robmono text-md w-full xs:w-3/4 sm:w-400px p-8">
          <div className="header">
            <h2>Connect your wallet </h2>
          </div>

          <p>
            Connect with one of our available wallet providers or create a new
            one.
          </p>

          {message}

          <div className="wallet-provider-list">
            {!isMobileOnly &&
              <button className="grow" onClick={connectMetaMask}>
                <img src={MetaMaskLogo} alt="metamask icon" /> Metamask
              </button>
            }
            <button className="grow" onClick={connectWallet}>
              <img src={WalletConnectLogo} alt="walletconnect icon" />
              WalletConnect
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </Modal>
  );
};

export default AuthModal;
