import React from "react";
import { isMobileOnly, isIOS } from 'react-device-detect';
import AnimatedHero from "../common/animatedHero";


function AnimatedHomePageHero() {
  var renderVideo = true;
  if (isMobileOnly && isIOS) renderVideo = false;
  return (

    <>
      <AnimatedHero bgimg="images/home_hero_banner.jpg" position="items-center pt-12 sm:pt-0 " className="hero-banner lg:bg-fixed bg-cover bg-center w-full bg-overly-shape flex flex-col items-center justify-center">
        <img className="pt-3 md:pt-16 md:pt-0" src="images/logo_with_border.png" alt="img"/>
        <div className={"bg-black blur-box sm:mt-0 " + (!renderVideo ? " mt-5 md:mt-16" : "")}>
          <p className="text-center text-2xl z-20">The biggest bunch of degenerates on the blockchain.</p>
        </div>
      </AnimatedHero>
    </>
  );
}

export default AnimatedHomePageHero;
