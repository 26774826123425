import React from "react";

function TwitterLink({ url = "", className = "" }) {

    return (
        <a href={url} target="_blank" rel="noreferrer" className={className}>
            <svg width={24} height={24} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                    d="M59.0988 15.0825C57.063 15.983 54.904 16.5744 52.6935 16.8371C55.0235 15.4436 56.7674 13.2505 57.6001 10.6665C55.4135 11.9678 53.0161 12.8798 50.5175 13.3731C48.8391 11.5773 46.6144 10.3864 44.1894 9.98536C41.7643 9.58435 39.2746 9.99578 37.1075 11.1557C34.9404 12.3156 33.2172 14.159 32.2058 16.3993C31.1944 18.6395 30.9515 21.1512 31.5148 23.5438C27.0804 23.3215 22.7424 22.1692 18.7822 20.1616C14.8221 18.154 11.3285 15.3359 8.52814 11.8905C7.53691 13.593 7.01602 15.5284 7.01881 17.4985C7.01881 21.3651 8.98681 24.7811 11.9788 26.7811C10.2082 26.7254 8.47649 26.2472 6.92814 25.3865V25.5251C6.92867 28.1003 7.81979 30.5961 9.4504 32.5893C11.081 34.5825 13.3508 35.9504 15.8748 36.4611C14.2311 36.9066 12.5076 36.9722 10.8348 36.6531C11.5465 38.8698 12.9335 40.8083 14.8017 42.1974C16.6699 43.5865 18.9258 44.3566 21.2535 44.3998C18.9401 46.2167 16.2913 47.5598 13.4585 48.3523C10.6257 49.1448 7.66448 49.3712 4.74414 49.0185C9.842 52.297 15.7764 54.0375 21.8375 54.0318C42.3521 54.0318 53.5708 37.0371 53.5708 22.2985C53.5708 21.8185 53.5575 21.3331 53.5361 20.8585C55.7198 19.2803 57.6044 17.3252 59.1015 15.0851L59.0988 15.0825Z"
                    fill="currentColor"
                />
            </svg>
        </a>
    );
}

export default TwitterLink;
