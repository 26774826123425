import React, { useState } from "react";
import Join from "../components/common/joinmodule";
import Footer from "../components/common/navbar/footer";
import Navbar from "../components/common/navbar/navbar";
import AnimatedDcredHero from "../components/dcredPage/animatedDcredHero";

export default function DcredPage() {
  const [menu, setmenu] = useState(false);
  return (
    <>
      <Navbar menu={menu} setmenu={setmenu} />
      <AnimatedDcredHero />
      <Join />
      <Footer />
    </>
  );
}
