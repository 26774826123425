import React from 'react'

const Web3FightClubBarFight = () => {
  return (
    <div className="w-full flex flex-col items-center px-4 pt-4 pb-20 md:py-20 bg-black relative">
      <div className="big-bg-text absolute z-0 inset-x-0 top-0 leading-tight text-center font-lavaarabic">DEGEN GANG</div>
      <div className="flex flex-row justify-center items-center w-full relative">
          <div className="flex flex-col items-center justify-center text-center mx-auto container max-w-screen-md text-white" data-aos="fade-up">
            <h3 className="font-lavaarabic text-5xl md:text-6xl leading-tight mb-6 text-center">WEB 3 FIGHT CLUB</h3>
            <p>The first rule of fight club, is to tell everyone about fight club.</p>
            <p>Bar Fights is a revolutionary <span className="text-lightGreen">cross-IP arcade NFT Fighting Game</span>, where different NFT collections can fight against each other to win and earn rewards through our play-to-earn mechanics.</p>
            <p>Battle it out over 3 rounds - the <span className="text-lightGreen">winner takes all</span>.</p>
            <p>Unlock maps and rewards, rank the leaderboards, challenge other fighters or even projects to a bar fight.</p>
          </div>
      </div>
  </div>
  )
}

export default Web3FightClubBarFight
