import React, { useState, useContext, createContext } from "react";
import { providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import detectEthereumProvider from "@metamask/detect-provider";

const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [showModalInfo, setShowModalInfo] = useState(true);

  const [account, setAccount] = useState(undefined);
  const [balance, setBalance] = useState(undefined);
  const [ethProvider, setETHProvider] = useState(undefined);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [isMetamask, setIsMetamask] = useState(undefined);

  const connectWallet = async () => {
    setIsMetamask(false);
    const provider = new WalletConnectProvider({
      infuraId: process.env.REACT_APP_INFURA_APIKEY,
    });
    setAccount(undefined);

    await provider.disconnect();
    await provider.enable().catch((e) => console.log("provider.enable", e));
    const web3Provider = new providers.Web3Provider(provider);
    setETHProvider(web3Provider);
    const signer = await web3Provider.getSigner();
    const address = await signer.getAddress();
    const balance = await signer.getBalance();
    setAccount(address);
    setBalance(balance);

    const network = await web3Provider.getNetwork();
    setWrongNetwork(
      !(process.env.REACT_APP_CHAN_ID === "0x" + network.chainId.toString())
    );
  };

  const connectMetaMask = async () => {
    setIsMetamask(true);
    const provider = await detectEthereumProvider();
    provider
      .request({ method: "eth_requestAccounts" })
      .then(handleAccountsChanged)
      .catch((err) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          // console.log("Please connect to MetaMask.");
          setIsMetamask(undefined);
        } else {
          console.error(err);
        }
      });

    const web3Provider = new providers.Web3Provider(provider);

    provider.on("chainChanged", handleChainChanged);
    provider.on("accountsChanged", handleAccountsChanged);

    async function handleAccountsChanged(accounts) {
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        // console.log("Please connect to MetaMask.");
        setAccount(undefined);
        setBalance(undefined);
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
        if (web3Provider) {
          const signer = await web3Provider.getSigner();
          const balance = await signer.getBalance();
          setBalance(balance);
        } else {
          // console.log("I am an error");
        }
      }
    }

    async function handleChainChanged(_chainId) {
      if (_chainId !== process.env.REACT_APP_CHAN_ID) {
        setWrongNetwork(true);
        try {
          setAccount(undefined);
          provider
            .request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: process.env.REACT_APP_CHAN_ID }],
            })
            .then(async () => {
              const signer = await ethProvider.getSigner();
              const balance = await signer.getBalance();
              setBalance(balance);
            });
        } catch (e) {}
      } else {
        setWrongNetwork(false);
      }
    }

    setETHProvider(web3Provider);

    const chainId = await provider.request({ method: "eth_chainId" });
    await handleChainChanged(chainId);
    const signer = await web3Provider.getSigner();
    const balance = await signer.getBalance();
    setBalance(balance);
  };

  const updateBalance = async () => {
    if (ethProvider) {
      const signer = await ethProvider.getSigner();
      const account = await signer.getAddress();
      const balance = await signer.getBalance();
      setAccount(account);
      setBalance(balance);
    }
  };

  const shortenAddress = (addr) => {
    if (addr !== undefined && addr.startsWith("0x")) {
      const length = addr.length;
      return addr.slice(0, 6) + "..." + addr.slice(length - 4);
    }
    return addr;
  };

  const disconnectWallet = () => {
    setAccount(undefined);
    setBalance(undefined);
    setETHProvider(undefined);
    setWrongNetwork(false);
  };

  return (
    <AuthContext.Provider
      value={{
        account,
        showModalInfo,
        setShowModalInfo,
        connectWallet,
        connectMetaMask,
        shortenAddress,
        ethProvider,
        balance,
        updateBalance,
        wrongNetwork,
        disconnectWallet,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
