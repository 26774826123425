import React from "react";
import { useNavigate } from "react-router-dom";


/* title = button title */
/* onclickhandler = what to do when someone clicks */
/* className = Appended to classname of button or container */
/* isthreed = Displays a 3D button */
/* text = Text settings */
/* navigateTo = Internal site location to navigate to */
/* externalUrl = Url to external site */
/* Render a smaller button */
function Button({ title, onClickhandler = null, className = null, isthreed = false, text = "xxs:text-2xs text-xs md:text-base", navigateTo = null, externalUrl = null, small = true }) {
  var navigate = useNavigate();
  function clickHandler() {
    if (onClickhandler != null) {
      //Click Handler Function Supplied - Call IT 
      onClickhandler();
    }
    else if (navigateTo != null) {
      //Navigate to an internal link supplied
      navigate(navigateTo);
    }
    return false;
  }
  return (
    <div>
      {
        isthreed ?
          <div>
            {externalUrl ?
              <div>
                <div className={"threedbutton font-wraith text-white px-4 py-2 " + className}>
                  <a className="" href={externalUrl} target="_blank" rel="noopener noreferrer">{title}</a>
                </div>
              </div>
              :
              <div className={"threedbutton font-wraith text-white " + className}>
                <button className={"px-4 py-2 " + text} onClick={clickHandler} >
                  <p className="-mt-1">{title}</p>
                </button>
              </div>
            }
          </div>
          :
          <div>
            {externalUrl ?
              <div>
                <a className={className + "font-spacegrotesk uppercase tracking-wider focus:outline-none text-base " + (small ? "px-5 sm:px-5 py-2 " : "px-8 py-3 md:py-11px") + " bg-redBrand text-white shadow-btn hover:shadow-none transition-all duration-300 ease-in-out hover:scale-110 hover:bg-limeGreen hover:text-redBrand transform "} href={externalUrl} target="_blank" rel="noopener noreferrer">{title}</a>
              </div>
              :
              <button
                className={className + "font-spacegrotesk uppercase tracking-wider focus:outline-none text-base " + (small ? "px-5 sm:px-5 py-2 " : "px-8 py-3 md:py-11px") + " bg-redBrand text-white shadow-btn hover:shadow-none transition-all duration-300 ease-in-out hover:scale-110 hover:bg-limeGreen hover:text-redBrand transform "}
                onClick={clickHandler}>
                {title}
              </button>
            }
          </div>
      }
    </div>
  );
}

export default Button;
