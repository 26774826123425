import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import Button from "../common/button";

const BarFightimage = () => {
  return (
    <div
      className="w-full bg-cover bg-no-repeat bar-fight-bg bar-fight-hero-bg relative"
      style={{ backgroundImage: `url(images/hero_video_poster.jpg)` }}
    >
      <Vimeo
        video="https://vimeo.com/793635597"
        autoplay
        loop
        background
        responsive
        playsInline
        controles
      />
      <div className="barfight-hero-btn text-center">
        <img
          className="md:w-4/12 lg:w-7/12 w-5/12 m-auto"
          src="/images/bar-fights-logo-new.png"
          alt=""
        />
        <Button
          externalUrl="https://beta.barfights.degengang.io/"
          className="font-lavaarabic focus:outline-none px-20 py-3 text-2xl md:py-11px bg-redBrand text-white shadow-btn hover:shadow-none hover:bg-limeGreen hover:text-redBrand"
          title="Play Game"
        />
        {/* <h3 class="leading-tight font-lavaarabic text-3xl md:text-5xl lg:text-6xl mb-6 text-center text-white">
          WEB3 FIGHT CLUB TOURNAMENT <br /> COMING SOON
        </h3> */}
      </div>
    </div>
  );
};

export default BarFightimage;
