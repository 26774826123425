import React from "react";

function HomePageBeastsModule() {
    return (
        <div className="w-full flex flex-col items-center px-4 pt-4 pb-10 xl:pt-20 bg-black relative">
            <div className="big-bg-text absolute z-0 inset-x-0 top-0 leading-tight text-center font-lavaarabic">DEGEN GANG</div>
            <div className="flex flex-row justify-center items-center w-full relative">
                <div className="flex flex-col items-center justify-center text-center mx-auto container max-w-screen-sm text-white" data-aos="fade-up">
                    <p>The year is 2089. The world has fallen into disrepair as the SEC and Centralised Banks have banned blockchain. Inflation tears through countries and communities. The previous beacon of hope, Schmelon Shmusk has fled on his spaceship to Mars along with his memes. But in this hour of darkness, there is one group that remains…</p>
                    <p>A group that still live the <span className="text-lightGreen">degen life</span>, sippin' on that sweet JPEG juice, chilling in the bar and always craving an opportunity to ape in.</p>
                    <p>Their ethos is simple. They are <span className="text-lightGreen">wild and free</span>. They take risks. They have fun. They fkn love memes. They <span className="text-lightGreen">have each others backs</span>, always. And above all, they are unapologetically degen to their core.</p>
                    <p>They call themselves <span className="text-lightGreen">Degen Gang</span>.</p>
                </div>
            </div>
        </div>
    );
}

export default HomePageBeastsModule;
