import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { isMobileOnly, isIOS } from "react-device-detect";
import AnimatedHero from "../common/animatedHero";
import Button from "../common/button";
import ConnectWalletButton from "../dappprovider/connectWalletButton";
import ReactModal from "react-modal";

function AnimatedHomePageHero() {
  var renderVideo = true;
  if (isMobileOnly && isIOS) renderVideo = false;

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);
  const handleShow = () => setModalOpen(true);

  return (
    <>
      <AnimatedHero
        bgimg="images/memes_hero.jpeg"
        position="items-center pt-12 sm:pt-0 "
        className="hero-banner lg:bg-fixed bg-cover bg-center w-full bg-overly-shape flex flex-col items-center justify-center"
      >
        <div
          className="w-10/12 2xl:w-4/12 xl:w-6/12 md:w-8/12 bg-black/[.7] flex flex-col items-center text-center justify-center mx-auto container py-10 px-6"
          data-aos="fade-up"
        >
          <p className="text-white text-5xl md:text-6xl pb-4 mb-3 font-lavaarabic">
            Enter <br /> The Meme Factory
          </p>
          <Button
            title="Lets Meme"
            className="font-lavaarabic focus:outline-none px-4 pt-3 pb-4 text-28 md:pt-4 md:pb-5 bg-redBrand text-white shadow-btn hover:shadow-none hover:bg-limeGreen hover:text-redBrand"
            onClickhandler={handleShow}
          />
        </div>

        {/* <Button
          title="Meme Factory"
          className="font-lavaarabic focus:outline-none px-4 py-4 text-28 md:pt-4 md:pb-5 bg-redBrand text-white shadow-btn hover:shadow-none hover:bg-limeGreen hover:text-redBrand"
          onClickhandler={handleShow}
        /> */}
        <ReactModal
          isOpen={modalOpen}
          overlayClassName="modalContainer"
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: "transparent",
              border: "0",
              zIndex: "99",
            },
            content: {
              maxWidth: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              border: "0",
              overflow: "visible",
              margin: "0 auto",
              inset: "0",
            },
          }}
        >
          <div className="popup-box">
            <iframe
              width="100%"
              height="500px"
              src="https://memefactory.degengang.io/browser/"
              title="Meme Factory"
              allow="clipboard-read; clipboard-write"
            />
            <div onClick={handleClose} className="handle-close">
              X
            </div>
          </div>
        </ReactModal>
      </AnimatedHero>
    </>
  );
}

export default AnimatedHomePageHero;
