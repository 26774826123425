import React, { useState } from "react";
import Join from "../components/common/joinmodule";
import Footer from "../components/common/navbar/footer";
import Navbar from "../components/common/navbar/navbar";
import AnimatedHomePageHero from "../components/homePage/animatedHomePageHero";
import HomePageBeastsModule from "../components/homePage/homePageBeastsModule";
import Web3FightClub from "../components/homePage/web3FightClub";
import Memes from "../components/homePage/memes";
import Team from "../components/homePage/team";
import DecredCoin from "../components/homePage/decredCoin";
import ParallaxDegenLogo from "../components/homePage/parallaxDegenLogo";
import Web3FightClub2 from "../components/homePage/web3FightClub2";

export default function Degen() {
  const [menu, setmenu] = useState(false);

  return (
    <>
      <Navbar menu={menu} setmenu={setmenu} />
      <AnimatedHomePageHero />
      <HomePageBeastsModule />
      <ParallaxDegenLogo/>
      <Web3FightClub2/>
      <Web3FightClub />
      <Memes/>
      <DecredCoin/>
      <Team/>
      <Join />
      <Footer />
    </>
  );
}
