import React from "react";

const AudioButton = ({handleClick, isAudioPlaying}) => {

  const handlePlayPauseButton = () => {
    isAudioPlaying = !isAudioPlaying;
    handleClick(isAudioPlaying)
  };

  return (
    <div
      className={`fixed bottom-6 right-6 z-50 play-btn ${!isAudioPlaying ? "audio-start" : ""}`}
    >
      <button onClick={handlePlayPauseButton}>Audio</button>
    </div>
  );
};

export default AudioButton;
