import React from "react";
import TwitterLink from "../common/twitterlink";

export default function Team() {
  return (
    <div className="bg-black h-full w-full py-10 relative">
      <div className="big-bg-text absolute z-0 inset-x-0 top-0 leading-tight text-center font-lavaarabic">
        degen mfs
      </div>
      <div
        id="team"
        className="flex flex-col items-center justify-center mx-auto container p-1 pb-8 text-white relative "
      >
        <h3 className="font-lavaarabic text-6xl" data-aos="fade-up">
          TEAM
        </h3>
        <div className="max-w-screen-md pt-10 text-center" data-aos="fade-up">
          <p className="mx-4 md:mx-10">
            The team is anonymous, however, have a combined experience of over
            30 years in blockchain.
          </p>
          <p className="mx-4 md:mx-10">
            With extensive backgrounds in marketing, business strategy, tech,
            finance and the art sectors, Degen Gang is poised to become a
            potential leader on the charge into the NFT P2E gaming space.
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8 max-w-screen-xl pt-10">
          <RoguesGallery
            imgUrl="images/overlord.png"
            title="Overlord Degen"
            companyPosition="Founder"
            twitterUrl="https://twitter.com/overlorddegen0"
            dataaosduration="400"
          />
          <RoguesGallery
            imgUrl="images/dr-degen.png"
            title="Dr Degen"
            companyPosition="Finance/Ops"
            dataaosduration="600"
          />
          <RoguesGallery
            imgUrl="images/thebull.png"
            title="The Bull"
            companyPosition="Ops/PM"
            dataaosduration="800"
          />
          <RoguesGallery
            imgUrl="images/windix.png"
            title="WinnDix"
            companyPosition="Marketing/Community"
            dataaosduration="1000"
          />
          <RoguesGallery
            imgUrl="images/tokenova.png"
            title="Tokenova"
            companyPosition="Advisors"
            twitterUrl="https://twitter.com/TokenovaCapital"
            dataaosduration="1100"
          />
          <RoguesGallery
            imgUrl="images/VonDoom.png"
            title="VonDoom.ETH"
            companyPosition="Advisor"
            twitterUrl="https://twitter.com/CryptoVonDoom "
            dataaosduration="1200"
          />
          <RoguesGallery
            imgUrl="images/lion.png"
            title="Dandelion Labs"
            companyPosition="Dev Team"
            webUrl="https://dandelionlabs.io/"
            dataaosduration="1300"
          />
          <RoguesGallery
            imgUrl="images/markus.png"
            title="Markus Manson"
            companyPosition="Artist"
            dataaosduration="1400"
            twitterUrl="https://twitter.com/markusmanson"
          />
        </div>
      </div>

      <div
        id="partners"
        className="flex flex-col items-center justify-center mx-auto container text-white relative pt-5 px-5"
      >
        <h3 className="font-lavaarabic text-5xl md:text-6xl" data-aos="fade-up">
          PARTNERS
        </h3>
        <div className="flex flex-col md:flex-row items-center justify-center mx-auto gap-x-8 gap-y-8 pb-5 md:pb-0 pt-6 md:pt-16 mt-8 sm:mt-2 relative z-10">
          <Partner
            href="https://twitter.com/ChungosNFT"
            imgUrl="images/chungo+logo.png"
            alt="chungo"
            dataaosduration="400"
          />
          <Partner
            href="https://cryptodadsnft.com/"
            imgUrl="images/crypto-dads.png"
            alt="crypto-dads"
            dataaosduration="400"
          />
          <Partner
            href="https://www.martiansvsroughnecks.com/"
            imgUrl="images/mvroughnecks_logo2_wide.png"
            alt="mvroughnecks"
            dataaosduration="600"
          />
          <Partner
            href="https://mooncatz.io/"
            imgUrl="images/mooncatz.png"
            alt="mooncatz"
          />
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center mx-auto gap-x-8 gap-y-8 pb-5 md:pb-0 relative z-10">
          <Partner
            href="#"
            imgUrl="images/the-plague-logo.png"
            alt="the_plague_logo"
            className="-mt-1"
            dataaosduration="800"
          />
          <Partner
            href="https://www.supremekong.com/"
            imgUrl="images/supreme_kong_logo_-_vertical_copy.png"
            alt="supreme_kong_logo"
            className="-mt-1"
            dataaosduration="800"
          />
          <Partner
            href="#"
            imgUrl="images/delegatecash-logo.png"
            alt="delegatecash_logo"
            dataaosduration="400"
          />
          <Partner
            href="https://duckdao.io/"
            imgUrl="images/duckdao.png"
            alt="duckdao"
            dataaosduration="400"
          />
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center mx-auto gap-x-8 gap-y-8 pb-5 md:pb-0 relative z-10">
        <Partner
            href="https://www.netvrk.co/"
            imgUrl="images/Netvrk_logo.png"
            alt="netvrk"
            dataaosduration="600"
          />
          <Partner
            href="https://www.truepnl.com/"
            imgUrl="images/truepnl-logo.png"
            alt="truepnl"
          />
          <Partner
            href="https://k9icks.io/"
            imgUrl="images/K9ICKS.png"
            alt="K9ICKS"
            className="-mt-1"
            dataaosduration="800"
          />
          <Partner
            href="https://www.rankerdao.com/"
            imgUrl="images/rankerdao.png"
            alt="rankerdao"
            dataaosduration="1000"
          />
          
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center mx-auto gap-x-8 gap-y-8 relative z-10">
        <Partner
            href="https://www.legion.ventures/"
            imgUrl="images/Legion Ventures_Logo.png"
            alt="legion ventures"
            dataaosduration="1100"
          />
          <Partner
            href="https://chillville.co/"
            imgUrl="images/Chillville.png"
            alt="Chillville"
            dataaosduration="1200"
          />
          <Partner
            href="https://oiginvest.com/"
            imgUrl="images/oig.png"
            alt="Oracles Investment Group"
            dataaosduration="1200"
          />
        </div>
        <div className="big-bg-text absolute z-0 inset-x-0 -bottom-28 lg:-bottom-40 leading-tight text-center font-lavaarabic">
          WAGMI
        </div>
      </div>
    </div>
  );
}

function RoguesGallery({
  imgUrl = "",
  title = "",
  companyPosition = "",
  twitterUrl = null,
  webUrl = null,
  dataaosduration = null,
}) {
  return (
    <div
      className="w-40 md:w-60"
      data-aos="fade-up"
      data-aos-duration={dataaosduration}
    >
      <img alt={companyPosition} className="w-44 md:w-60" src={imgUrl} />
      <div className="w-40 md:w-60 text-base">
        <div className="w-40 md:w-60 flex place-content-center font-semibold">
          {title}
        </div>
        <div className="w-40 md:w-60 font-thin flex place-content-center mb-2">
          {companyPosition}
        </div>
        <div className="w-40 md:w-60 font-thin flex place-content-center">
          {twitterUrl && <TwitterLink className="ml-2" url={twitterUrl} />}
        </div>
        <div className="w-40 md:w-60 font-thin flex place-content-center">
          {webUrl && (
            <a href={webUrl} target="__blank">
              <img src="images/web.png" className="w-6" alt="world wide web" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function Partner({
  href = "",
  imgUrl = "",
  alt = "",
  className = "",
  dataaosduration = null,
}) {
  return (
    <a
      href={href}
      target="__blank"
      rel="noreferrer"
      className={`py-3 md:py-4 ${className}`}
      data-aos-duration={dataaosduration}
      data-aos="fade-up"
    >
      <img className="w-52" src={imgUrl} alt={alt} />
    </a>
  );
}
