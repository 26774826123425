import React, { useState } from "react";
import Navbar from "../components/common/navbar/navbar";
import AnimatedDcredHero from "../components/thememefactory/animatedHero";
import FortuneFavours from "../components/thememefactory/fortunefavours";
import TheMemeFactory2 from "../components/thememefactory/thememefactory";
import Join from "../components/common/joinmodule";
import Footer from "../components/common/navbar/footer";

export default function TheMemeFactory() {
    const [menu, setmenu] = useState(false);
    return (
      <>
        <Navbar menu={menu} setmenu={setmenu} />
        <AnimatedDcredHero />
        <TheMemeFactory2/>
        <FortuneFavours/>
        <Join />
        <Footer />
      </>
    );
  }