import React from 'react'
import Button from "../common/button";

const DecredCoinBarFight = () => {
  return (
    <div style={{ backgroundImage: `url(images/decredCoin_bg.png)` }} className="bg-black bg-cover bg-center h-full w-full pt-14 pb-24 px-4"
    >
      <div className="flex flex-col items-center justify-center mx-auto container text-white lg:pr-8">
        <div className="grid grid-cols-1 md:grid-cols-8 gap-8 max-w-screen-lg dcred-block relative">
          <div className="md:col-span-4 flex flex-col items-center justify-center" data-aos="fade-up" data-aos-duration="300">
            <img className="" src="/images/decredCoin_pic.png" alt=""/>
          </div>
          <div className="md:col-span-4 bg-black/70 relative pr-6 pb-6 z-10">
            <h3 className="font-lavaarabic text-5xl md:text-6xl leading-tight mb-6" data-aos="fade-up" data-aos-duration="300">$DCRED</h3>
            <p data-aos="fade-up" data-aos-duration="300"><span className="text-lightGreen">$DCRED</span> (Degen Street Cred) is our in-game utility token that is fundamental to progressing within the game. Use $DCRED to purchase weapons, armour, finishing moves, open loot boxes and more.</p>
            <p data-aos="fade-up" data-aos-duration="600">$DCRED is earned by winning <span className="text-lightGreen">Bar Fights</span>. You'll need to be in the gang to claim your rewards though.</p>
            <p data-aos="fade-up" data-aos-duration="900">All Genesis Degen Gang holders can yield 100 $DCRED a day from looting sprees (staking).</p>
            <div data-aos="fade-up" data-aos-duration="1000">
              <Button title="$DCRED" navigateTo="/dcred" className="mt-3"/>
            </div>
          </div>
        </div>      
      </div>
    </div>
  )
}

export default DecredCoinBarFight

