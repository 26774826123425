import React from "react";
import Button from "../common/button";

function Web3FightClub() {
  return (
    <div className="bg-black w-full">
      <div className="flex flex-col items-center justify-center mx-auto container text-white relative z-10">
        <div className="max-w-screen-xl bg-contain bg-no-repeat fight-block mob-bg-none" style={{ backgroundImage: `url(images/homepage-fight.png)` }} >
        <div className="w-full text-center pb-10 md:hidden">
          <img className=""src="/images/fight.png" alt="" />
        </div>
          <div className="w-full md:w-6/12 lg:w-2/5 float-right text-white">
            <div className="bg-black">
              <h3
                className="font-lavaarabic text-5xl md:text-6xl"
                data-aos="fade-up"
              > BAR FIGHTS
              </h3>
              <p data-aos="fade-up" data-aos-duration="1000">
                Everyone wants to know who's the <span className="text-lightGreen">toughest mfr in the bar</span> and now
                you've got the opportunity to show them.
              </p>
              <p data-aos="fade-up" data-aos-duration="1500">
                Fight your way through this underground club and pretty soon
                you'll be ranking up and <span className="text-lightGreen">cracking skulls for breakfast</span>.
              </p>
              <p data-aos="fade-up" data-aos-duration="2000">
                There's some pretty dope shit to win in the fight club too, but
                you'll need to be in the gang to be able to claim your rewards.
              </p>
              <div data-aos="fade-up" data-aos-duration="2000">
                <Button title="Bar Fights" navigateTo="/barfights" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web3FightClub;
