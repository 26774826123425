import React, { useState, useEffect } from 'react';
import dead from "../../assets/Soundboard/dead.wav";
import degengang from "../../assets/Soundboard/degen-gang.wav";
import degen from '../../assets/Soundboard/degen.wav';
import diamondhands from '../../assets/Soundboard/diamond-hands.wav';
import dyor from '../../assets/Soundboard/dyor.wav';
import fight from '../../assets/Soundboard/fight.wav';
import firstblood from '../../assets/Soundboard/first-blood.wav';
import fud from '../../assets/Soundboard/fud.wav';
import gg from '../../assets/Soundboard/gg.wav';
import gm from '../../assets/Soundboard/gm.wav';
import gm2 from '../../assets/Soundboard/gm2.wav';
import initforthetech from '../../assets/Soundboard/in-it-for-the-tech.wav';
import lfg from '../../assets/Soundboard/lfg.wav';
import ngmi from '../../assets/Soundboard/ngmi.wav';
import paperhands from '../../assets/Soundboard/paper-hands.wav';
import probablynothing from '../../assets/Soundboard/probably-nothing.wav';
import rage from '../../assets/Soundboard/rage.wav';
import rekt from '../../assets/Soundboard/rekt.wav';
import round1 from '../../assets/Soundboard/round1.wav';
import rugpool from '../../assets/Soundboard/rug-pool.wav';
import wagmi from '../../assets/Soundboard/wagmi.wav';
import wenlambo from '../../assets/Soundboard/wen-lambo.wav';
import youlose from '../../assets/Soundboard/you-loose.wav';
import youwin from '../../assets/Soundboard/you-win.wav';

export const audioList = [
    { audio: new Audio(degengang), img: `images/profilebutton/degen-gang.png`,hoverimg:`images/profilebutton/degen-gang-hover.png` },
    { audio: new Audio(degen) , img: `images/profilebutton/degen.png`, hoverimg:`images/profilebutton/degen-hover.png`},
    { audio: new Audio(diamondhands) , img: `images/profilebutton/diamond-hands.png`, hoverimg:`images/profilebutton/diamond-hands-hover.png`},
    { audio: new Audio(dyor), img: `images/profilebutton/dyor.png`, hoverimg:`images/profilebutton/dyor-hover.png`},
    { audio: new Audio(fight), img: `images/profilebutton/fight.png`, hoverimg:`images/profilebutton/fight-hover.png`},
    { audio: new Audio(firstblood), img: `images/profilebutton/first-blood.png`, hoverimg:`images/profilebutton/first-blood-hover.png`},
    { audio: new Audio(fud), img: `images/profilebutton/fud.png`, hoverimg: `images/profilebutton/fud-hover.png`},
    { audio: new Audio(gg), img: `images/profilebutton/gg.png`, hoverimg: `images/profilebutton/gg-hover.png`},
    { audio: new Audio(gm), img: `images/profilebutton/gm.png`, hoverimg:`images/profilebutton/gm-hover.png`},
    { audio: new Audio(gm2), img: `images/profilebutton/gm2.png`, hoverimg:`images/profilebutton/gm2-hover.png`},
    { audio: new Audio(initforthetech), img: `images/profilebutton/in-it-for-the-tech.png`, hoverimg:`images/profilebutton/in-it-for-the-tech-hover.png`},
    { audio: new Audio(ngmi), img: `images/profilebutton/ngmi.png`, hoverimg:`images/profilebutton/ngmi-hover.png`},
    { audio: new Audio(paperhands), img: `images/profilebutton/paper-hands.png`, hoverimg:`images/profilebutton/paper-hands-hover.png`},
    { audio: new Audio(probablynothing), img: `images/profilebutton/probably-nothing.png`, hoverimg: `images/profilebutton/probably-nothing-hover.png`},
    { audio: new Audio(rage), img: `images/profilebutton/rage-mode.png`, hoverimg:`images/profilebutton/rage-mode-hover.png`},
    { audio: new Audio(rekt), img: `images/profilebutton/rekt.png`, hoverimg:`images/profilebutton/rekt-hover.png`},
    { audio: new Audio(round1), img: `images/profilebutton/round1.png`, hoverimg:`images/profilebutton/round1-hover.png`},
    { audio: new Audio(rugpool), img: `images/profilebutton/rug-pool.png`, hoverimg:`images/profilebutton/rug-pool-hover.png`},
    { audio: new Audio(wagmi), img: `images/profilebutton/wagmi.png`, hoverimg:`images/profilebutton/wagmi-hover.png`},
    { audio: new Audio(wenlambo), img: `images/profilebutton/wen-lambo.png`, hoverimg:`images/profilebutton/wen-lambo-hover.png`},
    { audio: new Audio(youlose), img: `images/profilebutton/you-lose.png`, hoverimg: `images/profilebutton/you-lose-hover.png`},
    { audio: new Audio(youwin), img: `images/profilebutton/you-win.png`, hoverimg:`images/profilebutton/you-win-hover.png`},
];