import React from "react";
import Button from "../common/button";

function BeastDegens() {
    return (
        <div style={{ backgroundImage: `url(images/beast-degens-bg.png)` }} className="bg-cover bg-center h-full w-full py-16 md:py-20 lg:py-20 xl:py-20 z-10">
            <div id="beastDegens" className="flex flex-col items-center justify-center text-center p-1 pb-8 px-2 xs:px-4 sm:px-8 text-white f-f-r ">
                <div className="px-8 lg:px-40 xl:px-64 flex flex-col items-center">
                    <img src="images/Beast Degens Logo Package_White 1.png" alt="Beast Degens" className="w-64 md:w-96" data-aos="fade-up" data-aos-duration="300"></img>
                    <p className="text-white pt-8" data-aos="fade-up" data-aos-duration="500">The rumours of scientists experimenting on degens turned out to be true… and now we have beasts to deal with… </p>
                    <p className="text-white pt-4" data-aos="fade-up" data-aos-duration="700">These creatures are chaotic, violent and brutal…</p>
                </div>
                <div className="flex flex-col lg:flex-row items-center justify-center gap-x-8 my-8 gap-y-8 px-2">
                    <video id="beasts-sectiona-left" className="lg:w-48pct mt-2" data-aos="fade-up" data-aos-duration="300" autoPlay loop muted playsInline >
                        <source src="images/beastpage-a.mp4" type="video/mp4" />
                    </video>
                    <video id="beasts-sectiona-right" className="lg:w-48pct mt-2" data-aos="fade-up" data-aos-duration="300" autoPlay loop muted playsInline>
                        <source src="images/beastpage-b.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="w-full flex flex-col items-center px-4 pt-4 pb-10 xl:pt-16 relative">
                    <div className="flex flex-row justify-center items-center w-full relative">
                        <div className="flex flex-col items-center justify-center text-center mx-auto container max-w-screen-md text-white">
                            <div data-aos="fade-up" className="mb-10">
                                <h3 className="font-lavaarabic text-5xl md:text-6xl text-white pb-16">Activate Beast Mode</h3>
                                <p>Take advantage of the evil scientists work and transform your degen into the ultimate beast....</p>
                                <p>Increase your HP, deal out 2X damage and come out victorious after activating beast mode.</p>
                            </div>
                            <div className="w-full" data-aos="fade-up" data-aos-duration="300">
                                <video id="barfights-video" className="hidden md:block" data-aos="fade-up" data-aos-duration="500" autoPlay loop muted playsInline>
                                    <source src="images/beasts-barfightsv3.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="images/Realm of the galaxie.png" alt="Evil or Based?" className="w-64 pt-8" data-aos="fade-up" data-aos-duration="300"></img>
                <div className="xl:px-40 pb-16">
                    <p className="text-white pt-8" data-aos="fade-up" data-aos-duration="400">The scientists created two factions of beasts, Evil and Based. </p>
                    <p className="text-white pt-2" data-aos="fade-up" data-aos-duration="500">Both possess different traits, abilities and madnesses that we are yet to truly understand…</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 place-items-center gap-y-6 lg:gap-y-8 gap-x-6 lg:gap-x-8 px-4 lg:px-20 pb-8">
                    <div className="w-full" data-aos="fade-up" data-aos-duration="300">
                        <img src="images/order1-min.jpg" alt="Order 1"></img>
                    </div>
                    <div className="w-full" data-aos="fade-up" data-aos-duration="500">
                        <img src="images/order2-min.jpg" alt="Order 1"></img>
                    </div>
                    <div className="w-full" data-aos="fade-up" data-aos-duration="700">
                        <img src="images/order3-min.jpg" alt="Order 1"></img>
                    </div>
                    <div className="w-full" data-aos="fade-up" data-aos-duration="900">
                        <img src="images/order4-min.jpg" alt="Order 1"></img>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 place-items-center gap-y-6 lg:gap-y-8 gap-x-6 lg:gap-x-8 px-4 lg:px-20">
                    <div className="w-full" data-aos="fade-up" data-aos-duration="300">
                        <video id="beast-red" autoPlay loop muted playsInline>
                            <source src="images/redfire.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="w-full" data-aos="fade-up" data-aos-duration="500">
                        <video id="beast-matrix" autoPlay loop muted playsInline>
                            <source src="images/matrix.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="w-full" data-aos="fade-up" data-aos-duration="700">
                        <video id="beast-space" autoPlay loop muted playsInline>
                            <source src="images/Space.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="w-full" data-aos="fade-up" data-aos-duration="900">
                        <video id="beast-zeus" autoPlay loop muted playsInline>
                            <source src="images/zeus.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="600" className="text-center mt-10">
            <Button title="BEAST DEGENS" externalUrl="https://opensea.io/collection/beastdegens" target="_blank"/>
            </div>
        </div>
    );
}

export default BeastDegens;
