import React from 'react'

const Web3FightClub2 = () => {
  return (
    <div className="bg-overlay bg-black relative px-4 pt-14 pb-24 md:pb-44 bg-no-repeat bg-left-bottom z-0" style={{ backgroundImage: `url(images/numbers_background.png)` }}>
      <div className="big-bg-text absolute z-0 inset-x-0 top-0 leading-tight text-center font-lavaarabic">FIGHT CLUB</div>
      <div className="h-auto w-full relative">
        <div className="flex flex-col items-center justify-center" data-aos="fade-up">
          <h3 className="font-lavaarabic text-5xl md:text-6xl text-white">WEB3 FIGHT CLUB</h3> 
        </div>
        <div className="flex flex-col items-center justify-center mx-auto container pt-5 text-white">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-12 max-w-screen-md pt-6">
          <div className="flex flex-col" data-aos="fade-up">
            <p>Notoriously known for vibin' too hard and starting <span className="text-lightGreen">bar fights</span>, this wild bunch of degens truly like to fuck shit up. They even created their own <span className="text-lightGreen">cross-IP web3 fight club</span>, deep in the underbelly of the blockchain.</p>
          </div>
            <div className="flex flex-col" data-aos="fade-up">
              <p>If you're down to throw chances are they'll let you fight.</p>
              <p>But be warned, they fight dirty.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mx-auto container text-white lg:-mt-6" data-aos="fade-up" data-aos-duration="1000">
          <div className="grid grid-cols-1 pl-0 lg:pl-8 max-w-screen-xl">
            <div className="flex flex-col items-center justify-center md:px-4">
              <img className=""src="/images/Degen_Fight_Club2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Web3FightClub2
