import React, { useState } from "react";
import Join from "../components/common/joinmodule";
import Footer from "../components/common/navbar/footer";
import Navbar from "../components/common/navbar/navbar";
import AnimatedBeastsHeroV2 from "../components/beastsPage/animatedBeastsHeroV2";
import BeastDegens from "../components/beastsPage/beastDegens";
import WhyBeastsV2 from "../components/beastsPage/whybeastsv2";
/* import titansOrOlympians from "../components/beastsPage/titansOrOlympians"; */
import TickerTape from "../components/common/tickerTape";
import BreedaBeastModule from "../components/beastsPage/breedabeast";

export default function BeastsPageV2() {
  const [menu, setmenu] = useState(false);

  return (
    <>
      <Navbar menu={menu} setmenu={setmenu} />
      {/* <AnimatedBeastsHeroV2 /> */}
      {/* <TickerTape text=" - WARNING - BEAST BREEDING IMMINENT - WARNING - BEAST BREEDING IMMINENT - WARNING - BEAST BREEDING IMMINENT - WARNING - BEAST BREEDING IMMINENT  - WARNING - BEAST BREEDING IMMINENT  - WARNING - BEAST BREEDING IMMINENT " /> */}
      <BeastDegens />
      <WhyBeastsV2 />
      {/*<titansOrOlympians />*/}
      {/*<BreedaBeastModule />*/}
      <Join />
      <Footer />
    </>
  );
}
