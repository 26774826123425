import React from "react";
import Button from "../common/button";

function JoinModule() {
  return (
    <div
      style={{ backgroundImage: `url(images/join_the_game_bg_img.png)` }}
      className="bg-cover bg-center h-full w-full py-24 md:py-32 lg:py-20 xl:py-60 z-10 relative"
    >
      <div className="w-10/12 2xl:w-4/12 xl:w-6/12 md:w-8/12 bg-black/[.7] flex flex-col items-center text-center justify-center mx-auto container py-10 px-6" data-aos="fade-up">
        <p className="text-white text-5xl md:text-6xl pb-4 mb-0 font-lavaarabic">
          Join the gang
        </p>
        <p className="text-white font-spacegrotesk m-0">
          So you want to join the gang?
        </p>
        <p className="text-white font-spacegrotesk text-center pb-5 m-0">
          We don't blame you. Come drop by in the discord and say gm.
        </p>
        <a href="http://discord.gg/VyxVr4byxH" target="__blank">
          <Button title="Discord" />
        </a>
      </div>
    </div>
  );
}

export default JoinModule;
