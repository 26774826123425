import React from 'react'

const ParallaxDegenLogo = () => {
  return (
    <div className='bg-overlay w-full text-center bg-cover flex justify-center items-center py-10 bg-no-repeat relative z-0' style={{ backgroundImage: `url(images/numbers_background.png)` }}>
      <img src="/images/degen-gang-skull-white.png" alt=""/>
    </div>
  )
}
export default ParallaxDegenLogo
